<template>
  <div v-if="isAuthenticated && isSage">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><md-icon style="color: black">euro_symbol</md-icon>&nbsp;&nbsp;&nbsp;Rechnungen</h3></div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-card>
          <md-card-content>
            <div class="grid-x grid-padding-x">
              <div class="cell large-2 medium-4 small-4">
                <md-field>
                  <label>Rechnungs-Status</label>
                  <md-select v-model="selectedBillingStates" @md-selected="onSelectBillingState" :disabled="sending" multiple>
                    <md-option v-for="billingState of labels.billingStates" :value="billingState" :key="billingState">
                      {{labels.billingState[billingState]}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-3 medium-6 small-6 text-center">
                <br><br>
                <span class="md-subheading ai-number">Summe: <b>{{settlementsSum | currency}}</b></span>
              </div>
              <div class="cell large-1 medium-2 small-2">
                <br>
                <md-button class="md-icon-button md-raised md-primary" style="background: cornflowerblue" :disabled="sending" @click="onSelectBillingState">
                  <md-icon>refresh</md-icon>
                  <md-tooltip md-direction="bottom" style="font-size: medium;">
                    Filter aktualisieren
                  </md-tooltip>
                </md-button>
              </div>
              <div class="cell large-6 hide-for-medium-only hide-for-small-only"></div>
              <div class="cell large-6 medium-12 small-12" style="height: 1rem;">
                <md-progress-bar md-mode="indeterminate" v-if="sending"/>
              </div>
              <div class="cell large-6 hide-for-medium-only hide-for-small-only"></div>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell large-2 medium-4 small-4">
                <md-switch class="md-primary" v-model="oneCustomerOnly" @change="onSelectCustomer" :disabled="!selectedSettlement">
                  Nur ein Kunde
                </md-switch>
              </div>
              <div class="cell large-4 medium-8 small-8">
                <div class="md-layout">
                  <div class="md-layout-item" style="padding-right: 15px;">
                    <md-datepicker id="from-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="startDate"
                                   md-immediately :disabled="sending" @md-closed="onCloseStartDatePicker">
                      <label>Startdatum</label>
                    </md-datepicker>
                  </div>
                  <div class="md-layout-item" style="padding-left: 15px;">
                    <md-datepicker id="until-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="untilDate"
                                   md-immediately :disabled="sending" @md-opened="onOpenUntilDatePicker" @md-closed="onCloseUntilDatePicker">
                      <label>Endedatum</label>
                    </md-datepicker>
                  </div>
                </div>
              </div>
            </div>
            <SettlementsView :settlements="filteredSettlements" :organizations="organizations"
                             :layout-classes="'cell large-6'"
                             @update-sum="onUpdateSettlementSum"
                             @update-selected-settlement="onUpdateSelectedSettlement"
                             @reload-settlements="onReloadSettlements"/>
          </md-card-content>
        </md-card>
      </div>
    </div>

  </div>

</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import SettlementsView from "../components/views/account/SettlementsView";
  import HttpErrorHandler from "../services/HttpErrorHandler";
  import SageService from "../services/SageService";
  import OrganizationService from "../services/OrganizationService";
  import Vue from 'vue';
  import jQuery from "jquery";

  export default {
    name: 'Settlements',

    components: {
      'vue-simple-spinner': Spinner,
      SettlementsView,
    },

    created() {
      this.initDatePickerSyncHandler(true);
    },

    beforeDestroy() {
      this.initDatePickerSyncHandler(false);
    },

    mounted() {
      if (this.isSage) {
        this.getAllSettlements(this.selectedBillingState);
        this.getOrganizations();
      }
    },

    data() {
      return {
        sending: false,
        success: false,
        oneCustomerOnly: false,

        selectedBillingState: 'ALL',
        selectedBillingStates: ['OPEN'],
        settlements: [],
        filteredSettlements: [],
        organizations: [],

        selectedSettlement: null,
        settlementsSum: 0,

        startDate: Vue.moment().add('-1','months').format('DD.MM.YYYY'),
        untilDate: Vue.moment().format('DD.MM.YYYY'),
      }
    },

    methods: {

      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#from-date-dp').children('input').bind('blur', () => {
              this.startDate = jQuery('#from-date-dp').children('input').val();
            });
            jQuery('#until-date-dp').children('input').bind('blur', () => {
              this.untilDate = jQuery('#until-date-dp').children('input').val();
            });
          }, 500);
        } else {
          jQuery('#from-date-dp').children('input').unbind('blur');
          jQuery('#until-date-dp').children('input').unbind('blur');
          jQuery('.md-button.md-icon-button.md-dense.md-input-action.md-clear').unbind('click');
        }
      },

      onOpenUntilDatePicker() {
        if ((this.startDate && !this.untilDate)) {
          this.untilDate = this.startDate;
        }
      },

      onCloseStartDatePicker() {
        setTimeout(() => this.startDate = jQuery('#from-date-dp').children('input').val(), 100);

        setTimeout(() => {
          jQuery('.md-button.md-icon-button.md-dense.md-input-action.md-clear').bind('click', () => {
            this.startDate = jQuery('#from-date-dp').children('input').val();
          });
        }, 250);
      },

      onCloseUntilDatePicker() {
        setTimeout(() => this.untilDate = jQuery('#until-date-dp').children('input').val(), 100);

        setTimeout(() => {
          jQuery('.md-button.md-icon-button.md-dense.md-input-action.md-clear').bind('click', () => {
            this.untilDate = jQuery('#until-date-dp').children('input').val();
          });
        }, 250);
      },

      getAllSettlements(state) {
        this.sending = true;
        let startDate = '';
        let untilDate = '';

        if (this.startDate) {
          startDate = Vue.moment(this.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        if (this.untilDate) {
          untilDate = Vue.moment(this.untilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        }

        SageService.getAllSettlements(state, startDate, untilDate)
          .then(response => {
            this.settlements = response.data;
            this.sending = false;
            this.filterSettlements(this.settlements);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Rechnungen mit Status <b>' + this.labels.billingState[state] + '</b>');
            this.sending = false;
          });
      },

      filterSettlements(settlements) {
        let filteredSettlements = [];
        settlements.forEach(settlement => {
          this.selectedBillingStates.forEach(state => {
            if (settlement.billingState === state) {
              if (this.oneCustomerOnly && this.selectedSettlement) {
                if (this.selectedSettlement.customerNo === settlement.customerNo)
                  filteredSettlements.push(settlement);
              } else {
                filteredSettlements.push(settlement);
              }
            }
          })
        })

        this.filteredSettlements = filteredSettlements;
        if (this.selectedSettlement) {
          setTimeout(() => jQuery('#' + this.selectedSettlement.billingNumber).click(), 50);
        }
      },

      onSelectBillingState() {
        this.startDate = jQuery('#from-date-dp').children('input').val();
        this.untilDate = jQuery('#until-date-dp').children('input').val();
        this.getAllSettlements(this.selectedBillingState);
      },

      onUpdateSettlementSum(sum) {
        this.settlementsSum = sum;
      },

      onUpdateSelectedSettlement(settlement) {
        this.selectedSettlement = settlement;
      },

      onSelectCustomer() {
        this.filterSettlements(this.settlements);
      },

      getOrganizations() {
        this.sending = true;
        OrganizationService.getAllOrganizations()
          .then(response => {
            this.organizations = response.data;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Organisationen');
            this.sending = false;
          });
      },

      onReloadSettlements() {
        if (this.isSage) {
          this.getAllSettlements(this.selectedBillingState);
        }
      }
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      labels() {
        return this.$store.getters.labels;
      },

      isSage() {
        return this.$store.getters.sage;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
